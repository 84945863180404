import React from "react";

import { SOCIAL_ICONS, SocialProfileStatus } from "src/types";
import { SocialProfile } from "../../../modals/AddSocialProfilesModal/AddSocialProfilesModal";
import TooltipPortal from "../../TooltipPortal/TooltipPortal";
import {
  existAtLeastOneSocialProfileWithStatus,
  existAtLeastOneSocialProfileWithWarningIcon,
  getTooltipBySocialProfileStatus,
  socialProfileStatusesWithTooltip,
  socialProfileStatusesWithWarning,
} from "./utils";
import SocialProfileStatusIcon from "./SocialProfileStatusIcon";
import SocialProfileLink from "./SocialProfileLink";
import SocialProfileIconsWrapper, {
  SocialProfileIconSizes,
} from "./SocialProfileIconsWrapper";

import "./SocialProfileIcons.scss";
import { ApiPairedIcon } from "../ApiPairedIcon";

interface SocialProfileIconsProps {
  data: SocialProfile[];
  size?: SocialProfileIconSizes;
  addDataImgSrc?: boolean;
}

function SocialProfileIcons({
  data,
  size,
  addDataImgSrc,
}: SocialProfileIconsProps) {
  const filteredData = Array.isArray(data)
    ? data
        .filter((item) =>
          Object.keys(SOCIAL_ICONS).includes(item.socialProvider),
        )
        ?.map((el) => ({
          ...el,
          icon: SOCIAL_ICONS[el.socialProvider],
        }))
    : [];

  return (
    <SocialProfileIconsWrapper size={size}>
      {filteredData.map((item) => (
        <SocialProfileLink
          key={item.socialUrl}
          status={item.status}
          url={item.socialUrl}
        >
          {/* {console.log("item", item.flags)} */}

          {socialProfileStatusesWithTooltip.includes(item.status) ||
          item?.flags?.length > 0 ? (
            <TooltipPortal
              contentClassName="suggestion-tooltip-content"
              content={getTooltipBySocialProfileStatus(item.status, item.flags)}
              autoSize
            >
              <img
                src={!addDataImgSrc ? item.icon : undefined}
                data-img-src={addDataImgSrc ? item.icon : undefined}
                alt={item.socialProvider}
              />
              {ApiPairedIcon(item)}
            </TooltipPortal>
          ) : (
            <>
              <img
                src={!addDataImgSrc ? item.icon : undefined}
                data-img-src={addDataImgSrc ? item.icon : undefined}
                alt={item.socialProvider}
              />
              {ApiPairedIcon(item)}
            </>
          )}
        </SocialProfileLink>
      ))}

      {existAtLeastOneSocialProfileWithWarningIcon(
        filteredData,
        socialProfileStatusesWithWarning,
      ) && (
        <SocialProfileStatusIcon
          status={SocialProfileStatus.NotFound}
          addDataImgSrc={addDataImgSrc}
        />
      )}
      {existAtLeastOneSocialProfileWithStatus(
        SocialProfileStatus.Fresh,
        filteredData,
      ) && (
        <SocialProfileStatusIcon
          status={SocialProfileStatus.Fresh}
          addDataImgSrc={addDataImgSrc}
        />
      )}
    </SocialProfileIconsWrapper>
  );
}

export default SocialProfileIcons;
