import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import "./GlobalMetaFieldList.scss";
import { MetafieldAccessMode } from "src/types/";
import { API_URLS } from "src/utils/API_URLS";
import {
  setGlobalMetaFieldList,
  setIsGlobalMetaFieldListLoading,
} from "src/redux";
import AnimatedDiv from "src/app/components/AnimatedDiv/AnimatedDiv";
import { Button } from "src/app/components/Button/Button";
import { RootState } from "src/redux/reducers";
import { showErrorToast } from "src/utils/methods";
import { ChangeSettingsSectionParam } from "src/app/SettingsModal/methods";
import { ExtensionsSection } from "../../Extensions";
import { GlobalMetaFieldListItem } from "./GlobalMetaFieldListItem";
import { SettingsButtons } from "../../../../components/SettingsTabContent/SettingsTabContent";
import ExtensionView from "../../ExtensionView";

export type GlobalMetaFields = {
  wsMetaFieldType: string;
  wsMetaFieldUuid: string;
  wsMetaFieldAccessMode: keyof typeof MetafieldAccessMode;
  wsMetaFieldIsRequired: boolean;
  wsMetaFieldDescription: string | null;
};

export type GlobalMetaFieldUserData = {
  creatorDatabaseFormMetaFields: {
    metaFields: GlobalMetaFields[];
    caption: string;
    description: string;
  };
  recruitmentFormUuid: string | null;
  wsExtensionType: string | null;
};

export const getWorkspaceWsGlobalTaskMetaFields = async (
  wsWorkspaceUuid: string,
): Promise<GlobalMetaFields[] | undefined> => {
  try {
    const response = await axios.get(
      API_URLS.getWorkspaceGlobalMetaFields.replace(
        ":wsWorkspaceUuid:",
        wsWorkspaceUuid,
      ),
    );
    return response.data.content;
  } catch (err) {
    console.error(err);
    showErrorToast();
  }
};

interface GlobalMetaFieldProps {
  setExtensionsSection: (section: ChangeSettingsSectionParam) => void;
  onClose: () => void;
  contextData: any;
}

export function GlobalMetaFieldList({
  setExtensionsSection,
  onClose,
}: GlobalMetaFieldProps) {
  const dispatch = useDispatch();

  const {
    mainReducer: { activeWorkspaceUuid },
    extensionReducer: { globalMetaFieldList },
  } = useSelector((state: RootState) => state);

  useEffect(() => {
    const prepareData = async () => {
      let creatorDatabaseFormMetaFields = null;
      dispatch(setIsGlobalMetaFieldListLoading(true));

      creatorDatabaseFormMetaFields =
        await getWorkspaceWsGlobalTaskMetaFields(activeWorkspaceUuid);

      if (!creatorDatabaseFormMetaFields) {
        return;
      }

      dispatch(setGlobalMetaFieldList(creatorDatabaseFormMetaFields));
    };

    prepareData();
  }, [activeWorkspaceUuid]);

  return (
    <ExtensionView
      goBackButtonLabel={
        <IDHFormattedMessage id="ws_extensions" defaultMessage="Extensions" />
      }
      handleGoBack={() =>
        setExtensionsSection(ExtensionsSection.ExtensionsList)
      }
      title={
        <IDHFormattedMessage
          id="ws_creator_database_meta_field_header"
          defaultMessage="Visibility and permissions"
        />
      }
    >
      <>
        <div className="meta-field-members-list">
          <AnimatedDiv className="global-meta-field-table">
            <div className="global-meta-field-table__head">
              <span className="global-meta-field-table__head-meta-field">
                <IDHFormattedMessage
                  id="ws_meta_field_field"
                  defaultMessage="Field"
                />
              </span>
              <span className="global-meta-field-table__head-members">
                <IDHFormattedMessage
                  id="ws_meta_field_peaople_with_access"
                  defaultMessage="PEOPLE WITH ACCESS"
                />
              </span>
              <span className="global-meta-field-table__head-actions" />
            </div>

            <div className="global-meta-field-table__body">
              {globalMetaFieldList &&
                globalMetaFieldList.map(
                  (metaField: { uuid: string; name: string; members: [] }) => {
                    return (
                      <GlobalMetaFieldListItem
                        data={metaField}
                        setExtensionsSection={setExtensionsSection}
                      />
                    );
                  },
                )}
            </div>
          </AnimatedDiv>
        </div>

        <SettingsButtons className="settings-tab-content__buttons-left-align">
          <>
            <Button
              size="large"
              onClick={() => {
                setExtensionsSection(ExtensionsSection.ExtensionsList);
              }}
              variant="white-with-border"
            >
              <IDHFormattedMessage id="ws_back" defaultMessage="Back" />
            </Button>
            <Button
              size="large"
              onClick={() => {
                onClose();
              }}
              variant="white-with-border"
            >
              <IDHFormattedMessage id="ws_close" defaultMessage="Close" />
            </Button>
          </>
        </SettingsButtons>
      </>
    </ExtensionView>
  );
}
