import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useParams,
  Link,
  useLocation,
} from "react-router-dom";

import { ReactComponent as RectangleCheckIcon } from "src/images/rectangle-check.svg";
import axios from "axios";
import classNames from "classnames";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import { TableSettingsDropdown } from "src/app/dropdowns/TableSettingsDropdown/TableSettingsDropdown";
import { ExtensionsDropdown } from "src/app/dropdowns/ExtensionsDropdown/ExtensionsDropdown";
import { SettingsModal } from "src/app/SettingsModal/SettingsModal";
import { SettingsContext, SettingsTab } from "src/app/SettingsModal/enums";
import { SeeAsClientInfoModal } from "src/app/modals/ShareModal/SeeAsClientInfoModal/SeeAsClientInfoModal";
import { ExtensionsSection } from "src/app/SettingsModal/tabs/Extensions/Extensions";
import {
  closeSettingsModal,
  openSettingsModal,
  removePropertyFromViewParam,
} from "src/app/SettingsModal/methods";
import { HowItWorksModal } from "src/app/SettingsModal/tabs/Extensions/components/RecruitmentForm/components/HowItWorksModal/HowItWorksModal";
import { getCouponsList } from "src/redux/coupon/couponActions";
import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import { GlobalTaskDetails } from "src/app/components/GlobalTaskDetails/GlobalTaskDetails";
import { useQuery } from "src/app/methods/useQuery";
import DictionaryDetails from "src/app/components/DictionaryDetails/DictionaryDetails";
import { useShowPane } from "src/hooks/useShowPane";
import NoResultsScreen from "src/app/components/NoResultsScreen/NoResultsScreen";
import noFilteredResultsImg from "src/images/empty-folder-group.svg";
import { AppLocation } from "src/redux/main/mainReducer";
import { RootState } from "src/redux/reducers";
import { TasksColumn } from "src/redux/task/taskReducer";
import {
  cleanGetProjectFailed,
  closeAddCreatorsModal,
  closeAddPublicationModal,
  closeAddTaskModal,
  getProject,
  openAddCreatorsModal,
  openAddPublicationModal,
  openAddTaskModal,
  setShowTask,
  getTasksAutocomplete,
  getTasksCounters,
  clearTasksCounters,
  setSelectedTasks,
  setTasksLoading,
  setTasksList,
  clearTaskList,
  clearProjectBasicData,
  clearExtensionList,
  setSelectedFilters,
} from "../../../redux";
import CustomizeFieldsDropdown from "../../dropdowns/CustomizeFieldsDropdown/CustomizeFieldsDropdown";
import {
  Breadcrumbs,
  BreadcrumbsItem,
} from "../../components/Breadcrumbs/Breadcrumbs";
import { Button } from "../../components/Button/Button";
import Task from "../../Task/Task";
import AddTaskModal from "../../modals/AddTaskModal";
import SingleProjectOverviewTableWrapper from "../SingleProjectOverviewTableWrapper";

import { ReactComponent as TableIcon } from "../../../images/settings-dropdown/table.svg";
import { ReactComponent as PlusIcon } from "../../../images/plus-transparent.svg";
import { ReactComponent as SelectedIcon } from "../../../images/selected.svg";
import { ReactComponent as FieldIcon } from "../../../images/field.svg";
import { ReactComponent as GroupIcon } from "../../../images/group.svg";
import { ReactComponent as CreatorIcon } from "../../../images/creator.svg";
import { ReactComponent as PaymentIcon } from "../../../images/payment.svg";
import { ReactComponent as PictureIcon } from "../../../images/picture.svg";
import { ReactComponent as MyTasksIcon } from "../../../images/menu-icons/my-tasks.svg";
import { ReactComponent as MessageIcon } from "../../../images/menu-icons/message.svg";
import { ReactComponent as FilterIcon } from "../../../images/filter.svg";
import { ReactComponent as CustomizeIcon } from "../../../images/settings-alt.svg";

import Board from "../../board/Board";
import { ImageOption } from "../../components/CustomSelect/CustomSelect";
import { IColumn } from "../Project";
import ShareProjectOrTaskModal from "../../modals/ShareProjectOrTaskModal/ShareProjectOrTaskModal";
import { API_URLS } from "../../../utils/API_URLS";
import AddCreatorsModal from "../../modals/AddCreatorsModal/AddCreatorsModal";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from "../../components/Dropdown/Dropdown";
import {
  isStringUuid,
  capitalizeFirstLetter,
  getDictionaryAutoCompletesForColumns,
  setQueryParam,
  getSelectDataSetForColumns,
  sleep,
} from "../../../utils/methods";
import { HIDDEN_FIELDS } from "../../../utils/variables";
import AnimatedDiv from "../../components/AnimatedDiv/AnimatedDiv";
import ManageFieldModal from "../../modals/ManageFieldModal/ManageFieldModal";
import { TaskType } from "../../../types";
import { Metadata } from "../../Task/Subtask/Subtask";
import { tableDataType } from "../../components/Table/Table";
import SortFieldsDropdown from "../../dropdowns/SortFieldsDropdown/SortFieldsDropdown";
import { Pane } from "../../components/Pane/Pane";
import FiltersView from "./components/FiltersView";
import { ProjectDetails } from "../../project-details/ProjectDetails";
import TasksTableWrapper from "./TasksTable/TasksTableWrapper";
import AddPublicationModal from "../../modals/AddPublicationModal";
import DeleteMetaFieldModal from "../../modals/DeleteMetaFieldModal/DeleteMetaFieldModal";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import { EmptyTable } from "./TasksTable/EmptyTable";
import { cancelRequestAxios } from "../../methods/CancelRequest";
import { RolesInfoModal } from "../../modals/ShareModal/RolesInfoModal/RolesInfoModal";
import MemberOrClientModal from "../../modals/MemberOrClientModal/MemberOrClientModal";
import { TASK_FIELDS_BLACKLIST } from "../../Task/components/TaskMetaFields/TaskMetaFields";
import { TopbarRight } from "./components/TopbarRight";
import { ProjectCover } from "./components/ProjectCover";
import { ProjectMenuDropdown } from "./dropdowns/ProjectMenuDropdown";
import WebsocketListener from "../../../WebsocketListener";
import { SingleProjectSelectedTasksBar } from "./components/SingleProjectSelectedTasksBar";
import { ifRowMatchesFilters } from "./TasksTable/tasksTableSorting/tableSortingFunctions/filterTableData";

import "./SingleProject.scss";

const BOARD_META_FIELDS_WHITELIST = [
  MetaFieldType.Member,
  MetaFieldType.Creator,
  MetaFieldType.Content,
  MetaFieldType.SingleSelect,
  MetaFieldType.Date,
  MetaFieldType.BoolVal,
];

export interface ColumnOption {
  uuid: string;
  label: string;
  value: string;
  type: MetaFieldType;
}

const cancelRequestAxiosObject = new cancelRequestAxios();

export default function SingleProject() {
  const [editedFieldData, setEditedFieldData] = useState<any>({});
  const [showAddTaskFieldModal, setShowAddTaskFieldModal] =
    useState<boolean>(false);

  const [showAccessLinkModal, setShowAccessLinkModal] =
    useState<boolean>(false);

  const [showAddProjectFieldModal, setShowAddProjectFieldModal] =
    useState<boolean>(false);

  const [showTaskFieldSetupModal, setShowTaskFieldSetupModal] = useState(false);
  const [showProjectFieldSetupModal, setShowProjectFieldSetupModal] =
    useState(false);

  const [taskFields, setTaskFields] = useState<IColumn[]>([]);
  const [currentTab, setCurrentTab] = useState<{
    value: string;
    label: React.ReactNode;
  }>({
    value: "list",
    label: (
      <ImageOption icon={TableIcon}>
        <IDHFormattedMessage id="ws_table" defaultMessage="Table" />
      </ImageOption>
    ),
  });
  const [boardHidden, setBoardHidden] = useState(true);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [shareModalTab, setShareModalTab] = useState<"share" | "members">(
    "share",
  );
  const [sortingColumn, setSortingColumn] = useState<ColumnOption | null>(null);
  const [columnOptions, setColumnOptions] = useState<ColumnOption[]>([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [showDeleteMetaFieldModal, setShowDeleteMetaFieldModal] =
    useState(false);
  const [showMemberOrClientModal, setShowMemberOrClientModal] = useState(false);
  const [showRulesInfoModal, setShowRolesInfoModal] = useState(false);
  const [isNewOverviewForClient, setIsNewOverviewForClient] = useState(false);

  const [showSeeAsClientInfoModal, setShowSeeAsClientInfoModal] =
    useState(false);
  const history = useHistory();

  const activeWorkspaceUuid = useSelector(
    (state: RootState) => state.mainReducer.activeWorkspaceUuid,
  );

  const settingsModalData = useSelector(
    (state: RootState) => state.mainReducer.settingsModalData,
  );

  const howItWorksModalData = useSelector(
    (state: RootState) => state.mainReducer.howItWorksModalData,
  );

  const identity = useSelector(
    (state: RootState) => state.mainReducer.identity,
  );

  const taskType = useSelector(
    (state: RootState) => state.projectReducer.taskType,
  );

  const getProjectFailed = useSelector(
    (state: RootState) => state.projectReducer.getProjectFailed,
  );

  const tasksCounters = useSelector(
    (state: RootState) => state.projectReducer.tasksCounters,
  );

  const projectMetaFields = useSelector(
    (state: RootState) => state.projectReducer.projectMetaFields,
  );

  const showTask = useSelector(
    (state: RootState) => state.projectReducer.showTask,
  );

  const projectBasicData = useSelector(
    (state: RootState) => state.projectReducer.projectBasicData,
  );

  const tasks = useSelector((state: RootState) => state.taskReducer.tasks);

  const tasksColumns = useSelector(
    (state: RootState) => state.taskReducer.tasksColumns,
  );

  const tasksLoading = useSelector(
    (state: RootState) => state.taskReducer.tasksLoading,
  );

  const showAddTaskModal = useSelector(
    (state: RootState) => state.taskReducer.showAddTaskModal,
  );

  const showAddCreatorsModal = useSelector(
    (state: RootState) => state.taskReducer.showAddCreatorsModal,
  );

  const showAddPublicationModal = useSelector(
    (state: RootState) => state.taskReducer.showAddPublicationModal,
  );

  const selectedFilters = useSelector(
    (state: RootState) => state.taskFiltersReducer.selectedFilters,
  );

  const dictionaryAutoCompletes = useSelector(
    (state: RootState) => state.dictionaryReducer.dictionaryAutoCompletes,
  );

  const params = useParams<{
    projectId: string;
    taskId: string;
    commentId: string;
  }>();

  const { projectId } = params;

  const [showPane, hidePane, setShowPane] = useShowPane(() => {
    history.push({
      pathname: `/workspace/${activeWorkspaceUuid}/projects/${projectId}`,
      search: `taskType=${taskType}`,
    });
  });

  const ref = useRef(null);
  const dispatch = useDispatch();
  const { search } = useLocation();

  const { path } = useRouteMatch();

  const query = useQuery();
  const showGlobalTask = isStringUuid(query.get("displayGlobalTask") || "");
  const showDiscoveryDetails = isStringUuid(
    query.get("displayDictionaryPreview") || "",
  );

  const lastTaskRank = tasks?.length ? tasks[tasks.length - 1].rank : "";

  useEffect(() => {
    if (getProjectFailed) {
      if (params.commentId) {
        history.push(
          `/workspace/${activeWorkspaceUuid}/my-actions/${params.projectId}/${params.taskId}/${params.commentId}`,
        );
      } else {
        history.push(
          `/workspace/${activeWorkspaceUuid}/my-actions/${params.projectId}/${params.taskId}`,
        );
      }

      dispatch(cleanGetProjectFailed());
    }
  }, [getProjectFailed]);

  useEffect(() => {
    if (activeWorkspaceUuid) {
      dispatch(getProject(projectId));
    }
  }, [activeWorkspaceUuid, projectId]);

  useEffect(() => {
    const id = setTimeout(() => {
      setBoardHidden(false);
    }, 150);
    return () => clearTimeout(id);
  }, [tasks]);

  useEffect(() => {
    dispatch(setSelectedTasks([]));
  }, [taskType]);

  useEffect(() => {
    let projectFields = [];
    if (tasksColumns.length) {
      projectFields = tasksColumns
        .sort((a: TasksColumn, b: TasksColumn) =>
          a.metaFieldRank?.localeCompare(b.metaFieldRank),
        )
        .map((field: TasksColumn) => ({
          id: field.metaFieldId,
          name: field.metaFieldName,
          type: field.metaFieldType,
          isVisible: field.metaFieldVisible,
          isRequired: field.metaFieldIsRequired,
          rank: field.metaFieldRank,
          key: field.metaFieldKey,
          valueSource: field.metaFieldValueSource,
          metaFieldOptions: field.data,
          metaFieldSource: field.metaFieldSource,
        }));
      setTaskFields(projectFields);
    }
  }, [tasksColumns, taskType]);

  useEffect(() => {
    if (!tasks?.length) return;
    const columnNames: ColumnOption[] = [];
    const tasksMetaData = tasks[0]?.metadata;

    if (tasksColumns.length) {
      tasksColumns
        .filter((field: TasksColumn) =>
          tasksMetaData?.find(
            (metafield: { uuid: string; type: MetaFieldType }) =>
              metafield.uuid === field.metaFieldId &&
              BOARD_META_FIELDS_WHITELIST.includes(metafield.type),
          ),
        )
        .forEach((column: TasksColumn) =>
          columnNames.push({
            uuid: column.metaFieldId,
            label: column.metaFieldName,
            value: column.metaFieldName,
            type: column.metaFieldType,
          }),
        );
    }
    setColumnOptions(columnNames);
  }, [tasksColumns, taskType]);

  useEffect(() => {
    const findOption = columnOptions?.find(
      (option) => option.uuid === sortingColumn?.uuid,
    );

    if (!sortingColumn || !findOption) {
      setSortingColumn(columnOptions[0]);
    }
  }, [columnOptions]);

  useEffect(() => {
    dispatch(setShowTask(isStringUuid(params.taskId)));
  }, [params.taskId, activeWorkspaceUuid]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const paramTaskType = searchParams.get("taskType");
    if (paramTaskType) return;
    if (taskType) {
      setQueryParam(history, "taskType", taskType);
    } else {
      setQueryParam(history, "taskType", TaskType.Creator);
    }
  }, [search, taskType]);

  useEffect(() => {
    if (shareModalTab === "members") {
      setShareModalTab("share");
    }
  }, [shareModalTab]);

  useEffect(() => {
    const getNewTasksList = async () => {
      try {
        await sleep(15);
        dispatch(setTasksLoading(true));

        const url = `${API_URLS.getTasksList.replace(
          ":projectUuid:",
          params.projectId,
        )}`;

        cancelRequestAxiosObject.cancelAndCreateToken();

        const cancelToken =
          await cancelRequestAxiosObject?.cancel_request?.token;

        const response = await axios.get(url, {
          params: {
            wsTaskType: taskType,
          },
          method: "GET",
          ...(cancelToken && { cancelToken }),
        });
        cancelRequestAxiosObject.resetCancelToken();

        const {
          tasks: newTasks,
          columns: newTasksColumns,
          titleMetaFieldId,
        } = response.data.content;

        await dispatch(
          setTasksList(newTasks, newTasksColumns, titleMetaFieldId),
        );
      } catch (error) {
        if (axios.isCancel(error)) {
          // console.error("Request canceled", error.message);
        }
      } finally {
        dispatch(setTasksLoading(false));
      }
    };
    getNewTasksList();
  }, [taskType, currentTab.value, params.projectId]);

  useEffect(() => {
    dispatch(getTasksAutocomplete(params.projectId, "creator"));
    dispatch(getTasksAutocomplete(params.projectId, "content"));
    dispatch(getTasksAutocomplete(params.projectId, "publication"));
    dispatch(getCouponsList(activeWorkspaceUuid));
  }, []);

  useEffect(() => {
    if (tasksColumns.findIndex((taskColumn) => taskColumn.isMocked) !== -1)
      return;

    const taskColumns = tasksColumns.map((column: TasksColumn) => ({
      type: column.metaFieldType,
      options: column.data,
    }));

    const projectColumns = projectMetaFields.map((column) => ({
      type: column.type,
      options: column.data,
    }));

    const columns = [...taskColumns, ...projectColumns];

    const filteredColumns =
      columns.filter(
        (column) =>
          !dictionaryAutoCompletes[
            `dictionaryType_${column?.options?.wsDictionaryUuid}`
          ],
      ) || [];

    if (filteredColumns.length) {
      getDictionaryAutoCompletesForColumns(dispatch, filteredColumns);
    }

    getSelectDataSetForColumns(dispatch, columns);
  }, [tasksColumns, projectMetaFields, dictionaryAutoCompletes]);

  useEffect(() => {
    const getTasksTabsCounters = () => {
      dispatch(getTasksCounters(projectId));

      return () => {
        dispatch(clearTasksCounters());
      };
    };
    getTasksTabsCounters();
  }, [projectId]);

  useEffect(() => {
    dispatch(clearProjectBasicData());
    dispatch(clearTaskList());
    dispatch(clearExtensionList());
  }, []);

  const changeTableData = (newValue: any) => {
    setBoardHidden(true);
    setSortingColumn((state: any) => ({
      ...state,
      uuid: newValue.uuid,
      label: newValue.label,
      type: newValue.type,
    }));
    setTimeout(() => {
      setSortingColumn(newValue);
      setBoardHidden(false);
    }, 200);
  };

  const changeTab = (newTab: TaskType) => {
    if (newTab === taskType) {
      return;
    }

    setBoardHidden(true);

    setTimeout(() => {
      dispatch(clearTaskList());
      setQueryParam(history, "taskType", newTab);
    }, 50);
  };

  if (!projectBasicData) {
    return null;
  }

  const getTaskTypeIcon = () => {
    switch (taskType) {
      case TaskType.Creator:
        return <CreatorIcon />;
      case TaskType.Content:
        return <PictureIcon />;
      case TaskType.Action:
        return <MyTasksIcon />;
      case TaskType.Publication:
        return <MessageIcon />;
      case TaskType.Payment:
        return <PaymentIcon />;
      default:
        return null;
    }
  };

  const constructData = () =>
    tasks.map((singleTask: any) => {
      const { taskId, title, ...taskData } = singleTask;

      return {
        ...taskData,
        projectId: singleTask.taskId,
        projectName: singleTask.title,
        metadata: singleTask.metadata,
        parentType: "ws_task",
      };
    });

  const openShareModal = () => {
    openSettingsModal(
      history,
      AppLocation.SingleProject,
      SettingsContext.Project,
      SettingsTab.Members,
    );
  };

  const handleAddTaskModal = () => {
    switch (taskType) {
      case TaskType.Creator:
        dispatch(openAddCreatorsModal());
        break;

      case TaskType.Publication:
        dispatch(openAddPublicationModal());
        break;

      default:
        dispatch(openAddTaskModal());
        break;
    }
  };

  const filteredTasksCount = tasks.reduce(
    (acc: number, task: any) =>
      acc + Number(ifRowMatchesFilters(task, selectedFilters, identity.id)),
    0,
  );

  return !activeWorkspaceUuid ? null : (
    <AnimatedDiv
      ref={ref}
      className={classNames(
        "projects",
        "projects--single-project",
        {
          "projects--board": currentTab.value === "board",
        },
        "scroll-fixed",
      )}
    >
      <Switch>
        <Route path={`${path}/details`}>
          <Pane hidePane={hidePane} showPane={showPane}>
            <ProjectDetails hidePane={hidePane} />
          </Pane>
        </Route>
      </Switch>
      <div className="projects__part-above-table">
        <div className="projects__topbar">
          <div className="projects__topbar-left">
            <Breadcrumbs>
              <BreadcrumbsItem
                text={
                  <IDHFormattedMessage
                    id="ws_campaigns"
                    defaultMessage="Campaigns"
                  />
                }
                to={`/workspace/${activeWorkspaceUuid}/projects`}
              />
              <BreadcrumbsItem
                text={String(projectBasicData.projectName)}
                to={`/workspace/${activeWorkspaceUuid}/projects/${projectBasicData.projectId}`}
              />
            </Breadcrumbs>
          </div>
          <TopbarRight />
        </div>

        <div className="projects__project-main-container">
          <div className="projects__project-name-container">
            <ProjectCover />
            <Link
              to={{
                pathname: `/workspace/${activeWorkspaceUuid}/projects/${projectBasicData.projectId}/details`,
                search: `taskType=${taskType}`,
              }}
            >
              <div
                className="projects__project-name"
                onClick={() => setShowPane(true)}
              >
                {projectBasicData.projectName}
              </div>
            </Link>

            <ProjectMenuDropdown
              data={projectBasicData}
              singleProject
              openProjectDetailsPane={() => setShowPane(true)}
            />
          </div>
          {projectBasicData.permissions?.project.includes(
            "project_meta_fields_management",
          ) && (
            <div className="projects__overview-container">
              <Button
                size="thin"
                variant="white-to-light"
                onClick={() =>
                  projectBasicData.clientMode
                    ? setShowMemberOrClientModal(true)
                    : setShowAddProjectFieldModal(true)
                }
              >
                <PlusIcon />
                <IDHFormattedMessage
                  id="ws_add_overview"
                  defaultMessage="Add overview"
                />
              </Button>
              <CustomizeFieldsDropdown
                setShowAddFieldModal={
                  projectBasicData.clientMode
                    ? () => setShowMemberOrClientModal(true)
                    : () => setShowAddProjectFieldModal(true)
                }
                setShowFieldSetupModal={setShowProjectFieldSetupModal}
                fieldData={editedFieldData}
                setFieldData={setEditedFieldData}
                dropdownButton={
                  <Button variant="white" size="thin">
                    <CustomizeIcon />
                    <IDHFormattedMessage
                      id="ws_overview_settings"
                      defaultMessage="Overview settings"
                    />
                  </Button>
                }
                fields={projectMetaFields
                  ?.filter((field) =>
                    projectMetaFields?.find(
                      (metafield) =>
                        metafield.uuid === field.uuid &&
                        metafield.taskType === taskType &&
                        !HIDDEN_FIELDS.includes(field.key),
                    ),
                  )
                  ?.map((field: { name: string }) => ({
                    ...field,
                    title: field.name,
                  }))}
                dataType={tableDataType.Project}
                title={
                  <IDHFormattedMessage
                    id="ws_overview_settings"
                    defaultMessage="Overview settings"
                  />
                }
                subheader={
                  <IDHFormattedMessage
                    id="ws_overview"
                    defaultMessage="Overview"
                  />
                }
                addBtnText={
                  <IDHFormattedMessage
                    id="ws_add_overview"
                    defaultMessage="Add overview"
                  />
                }
              />
            </div>
          )}
        </div>

        {projectBasicData && (
          <SingleProjectOverviewTableWrapper
            data={[projectBasicData]}
            singleProject
            openAddProjectFieldModal={() =>
              projectBasicData.clientMode
                ? setShowMemberOrClientModal(true)
                : setShowAddProjectFieldModal(true)
            }
          />
        )}
      </div>

      <div className="projects__topbar projects__topbar--tasks-table">
        <div className="projects__topbar">
          <Tabs>
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_creators"
                  defaultMessage="Creators"
                />
              }
              active={taskType === TaskType.Creator}
              onClick={() => changeTab(TaskType.Creator)}
              count={tasksCounters?.creators}
            />
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_content" defaultMessage="Content" />
              }
              active={taskType === TaskType.Content}
              onClick={() => changeTab(TaskType.Content)}
              count={tasksCounters?.content}
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_publications"
                  defaultMessage="Publications"
                />
              }
              active={taskType === TaskType.Publication}
              onClick={() => changeTab(TaskType.Publication)}
              count={tasksCounters?.publications}
            />
            <Tab
              tabText={
                <IDHFormattedMessage
                  id="ws_payments"
                  defaultMessage="Payments"
                />
              }
              active={taskType === TaskType.Payment}
              onClick={() => changeTab(TaskType.Payment)}
              count={tasksCounters?.payments}
            />
            <Tab
              tabText={
                <IDHFormattedMessage id="ws_actions" defaultMessage="Actions" />
              }
              active={taskType === TaskType.Action}
              onClick={() => changeTab(TaskType.Action)}
              count={tasksCounters?.actions}
            />
            <div className="projects__topbar-toolbar">
              {projectBasicData.permissions?.project.includes(
                "task_management",
              ) && (
                <Dropdown>
                  {(isOpen, setIsOpen) => (
                    <>
                      <Button
                        size="small"
                        textWithIcon
                        variant="blue"
                        onClick={() => setIsOpen(true)}
                      >
                        <PlusIcon />
                        <IDHFormattedMessage id="ws_add" defaultMessage="Add" />
                      </Button>
                      <DropdownMenu isOpen={isOpen}>
                        <DropdownMenuItem
                          className="projects__topbar-right-add-button"
                          onClick={handleAddTaskModal}
                        >
                          {getTaskTypeIcon()}
                          <IDHFormattedMessage
                            id={`ws_${taskType.toLowerCase()}`}
                            defaultMessage={taskType}
                          />
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => setShowAddTaskFieldModal(true)}
                        >
                          <FieldIcon />
                          <IDHFormattedMessage
                            id="ws_table_column"
                            defaultMessage="Table column"
                          />
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={() => setShowAccessLinkModal(true)}
                        >
                          <RectangleCheckIcon />
                          <IDHFormattedMessage
                            id="ws_form"
                            defaultMessage="Form"
                          />
                        </DropdownMenuItem>
                      </DropdownMenu>
                    </>
                  )}
                </Dropdown>
              )}

              {currentTab.value === "board" && (
                <Dropdown toRight>
                  {(isOpen, setIsOpen) => (
                    <>
                      <Button
                        size="small"
                        textWithIcon
                        variant="white"
                        onClick={() => setIsOpen(true)}
                      >
                        <GroupIcon />
                        <IDHFormattedMessage
                          id="ws_group_by"
                          defaultMessage="Group by"
                        />
                        :&nbsp;
                        {sortingColumn?.label}
                      </Button>
                      <DropdownMenu isOpen={isOpen}>
                        {columnOptions.length ? (
                          columnOptions.map(
                            (option: ColumnOption, index: number) => (
                              <DropdownMenuItem
                                key={`${option.value}-${index}`}
                                dark={option.value === sortingColumn?.value}
                                onClick={() => {
                                  changeTableData(option);
                                  setIsOpen(false);
                                }}
                              >
                                {option.label}

                                {option.value === sortingColumn?.value && (
                                  <SelectedIcon
                                    style={{ marginLeft: 8, marginRight: 0 }}
                                  />
                                )}
                              </DropdownMenuItem>
                            ),
                          )
                        ) : (
                          <DropdownMenuItem>
                            <IDHFormattedMessage
                              id="ws_nothing_to_sort"
                              defaultMessage="Nothing to sort"
                            />
                          </DropdownMenuItem>
                        )}
                      </DropdownMenu>
                    </>
                  )}
                </Dropdown>
              )}

              {currentTab.value === "list" && (
                <>
                  {tasks.length > 0 && (
                    <>
                      <Pane
                        showPane={filtersVisible}
                        hidePane={() => setFiltersVisible(false)}
                        width={309}
                      >
                        <FiltersView
                          filtersVisible={filtersVisible}
                          setFiltersVisible={setFiltersVisible}
                          taskType={taskType}
                          dataType={tableDataType.Task}
                          data={constructData()}
                        />
                      </Pane>
                      <Button
                        variant="white"
                        textWithIcon
                        size="small"
                        onClick={() => setFiltersVisible((prev) => !prev)}
                        active={selectedFilters?.selections?.length > 0}
                      >
                        <FilterIcon />
                        <IDHFormattedMessage
                          id="ws_filter"
                          defaultMessage="Filter"
                        />
                        {selectedFilters?.selections?.length > 0 &&
                          `: ${selectedFilters?.selections?.length}`}
                      </Button>
                    </>
                  )}
                  {tasks.length > 0 && (
                    <SortFieldsDropdown
                      fields={taskFields
                        ?.filter((field) => {
                          const tasksMetaData = tasks[0]?.metadata;
                          return tasksMetaData?.find(
                            (metafield: Metadata) =>
                              metafield.uuid === field.id &&
                              !HIDDEN_FIELDS.includes(field.name),
                          );
                        })
                        ?.map((field) => ({
                          ...field,
                          title: field.name,
                        }))}
                    />
                  )}
                </>
              )}

              <ExtensionsDropdown location={AppLocation.SingleProject} />

              <TableSettingsDropdown
                setShowAddFieldModal={setShowAddTaskFieldModal}
                setShowFieldSetupModal={setShowTaskFieldSetupModal}
                fieldData={editedFieldData}
                setFieldData={setEditedFieldData}
                fields={tasksColumns
                  .filter(
                    (field: TasksColumn) =>
                      !TASK_FIELDS_BLACKLIST.includes(field.metaFieldKey),
                  )
                  .map((column: TasksColumn) => ({
                    id: column.metaFieldId,
                    name: column.metaFieldName,
                    isRequired: column.metaFieldIsRequired,
                    isVisible: column.metaFieldVisible,
                    key: column.metaFieldKey,
                    metaFieldOptions: column.data,
                    metaFieldSource: column.metaFieldSource,
                    rank: column.metaFieldRank,
                    title: column.metaFieldName,
                    type: column.metaFieldType,
                    valueSource: column.metaFieldValueSource,
                  }))} // tasksColumns are mapped because old CustomizeFieldsDropdown component required this object template
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                permissions={projectBasicData.permissions}
              />
            </div>
          </Tabs>
        </div>

        {/* <TableSearch visible value="" setValue={() => ""} /> */}
      </div>

      {currentTab.value === "list" ? (
        <TasksTableWrapper columns={tasksColumns} />
      ) : null}

      {currentTab.value === "board" ? (
        <Board
          tasks={tasks.map((singleTask: any) => {
            const { taskId, title, ...taskData } = singleTask;

            return {
              ...taskData,
              projectId: singleTask.taskId,
              projectName: singleTask.title,
              metadata: singleTask.metadata
                // .filter((field: { type: string }) =>
                //   BOARD_META_FIELDS_WHITELIST.includes(field.type)
                // )
                .map((metafield: Metadata) => {
                  if (metafield.type === "date" && metafield.value) {
                    return {
                      ...metafield,
                      value: metafield.value.date,
                    };
                  }

                  return metafield;
                }),
              parentType: "ws_task",
            };
          })}
          fields={taskFields}
          column={sortingColumn}
          hidden={boardHidden}
        />
      ) : null}

      {filteredTasksCount === 0 &&
        currentTab.value === "list" &&
        !tasksLoading &&
        selectedFilters?.selections &&
        selectedFilters.selections.length !== 0 && (
          <div className="no-filtered-results-screen-wrapper">
            <NoResultsScreen
              title={
                <IDHFormattedMessage
                  id="ws_no_results_found"
                  defaultMessage="No results found"
                />
              }
              subtitle={
                <IDHFormattedMessage
                  id="ws_remove_filters"
                  defaultMessage="No results match the filter criteria. Remove filter or clear all filters to show results."
                />
              }
              imgUrl={noFilteredResultsImg}
              bottomContent={
                <Button
                  size="large"
                  variant="blue"
                  onClick={() =>
                    dispatch(setSelectedFilters({ selections: [] }))
                  }
                >
                  <IDHFormattedMessage
                    id="ws_clear_filters"
                    defaultMessage="Clear filters"
                  />
                </Button>
              }
            />
          </div>
        )}

      {currentTab.value === "list" &&
        !tasksLoading &&
        tasks.length === 0 &&
        (selectedFilters?.selections == null ||
          (selectedFilters?.selections &&
            selectedFilters.selections.length === 0)) && (
          <EmptyTable taskType={taskType} />
        )}

      <SingleProjectSelectedTasksBar />

      {showAddTaskModal && projectBasicData && (
        <AddTaskModal
          projectId={projectBasicData.projectId}
          onClose={() => dispatch(closeAddTaskModal())}
          lastTaskRank={lastTaskRank}
          tasksLength={tasks?.length}
        />
      )}

      {showAddPublicationModal && projectBasicData && (
        <AddPublicationModal
          projectId={projectBasicData.projectId}
          onClose={() => dispatch(closeAddPublicationModal())}
          lastTaskRank={lastTaskRank}
        />
      )}

      {showAddCreatorsModal && projectBasicData && (
        <AddCreatorsModal
          projectId={projectBasicData.projectId}
          onClose={() => dispatch(closeAddCreatorsModal())}
          lastTaskRank={lastTaskRank}
        />
      )}

      {showShareModal && (
        <ShareProjectOrTaskModal
          onClose={() => setShowShareModal(false)}
          data={projectBasicData}
          addMemberUrl={API_URLS.addProjectMembers}
          removeMemberUrl={API_URLS.removeProjectMember}
          variableName="wsProjectUuid"
          updateFunction={() => dispatch(getProject(projectId))}
          tab={shareModalTab}
        />
      )}

      {showAddTaskFieldModal && (
        <ManageFieldModal
          onClose={() => setShowAddTaskFieldModal(false)}
          dataType={tableDataType.Task}
          title={
            <IDHFormattedMessage
              id="ws_add_table_column"
              defaultMessage="Add table column"
            />
          }
          inputLabelText={
            <IDHFormattedMessage
              id="ws_column_title"
              defaultMessage="Column title"
            />
          }
          projectId={projectId}
        />
      )}

      {showAccessLinkModal && (
        <ManageFieldModal
          onClose={() => setShowAccessLinkModal(false)}
          dataType={tableDataType.Task}
          title={
            <IDHFormattedMessage
              id="ws_add_table_column"
              defaultMessage="Add table column"
            />
          }
          inputLabelText={
            <IDHFormattedMessage
              id="ws_column_title"
              defaultMessage="Column title"
            />
          }
          defaultOption={MetaFieldType.AccessLink}
          defaultFieldName={`${capitalizeFirstLetter(taskType)} Form`}
          hidden
          projectId={projectId}
        />
      )}

      {showAddProjectFieldModal && (
        <ManageFieldModal
          onClose={() => setShowAddProjectFieldModal(false)}
          dataType={tableDataType.Project}
          title={
            <IDHFormattedMessage
              id="ws_add_overview"
              defaultMessage="Add overview"
            />
          }
          inputLabelText={
            <IDHFormattedMessage
              id="ws_overview_title"
              defaultMessage="Overview title"
            />
          }
          isNewOverviewForClient={isNewOverviewForClient}
          projectId={projectId}
        />
      )}

      {showTaskFieldSetupModal && (
        <ManageFieldModal
          data={editedFieldData}
          onClose={() => setShowTaskFieldSetupModal(false)}
          dataType={tableDataType.Task}
          projectId={projectId}
        />
      )}

      {showProjectFieldSetupModal && (
        <ManageFieldModal
          data={editedFieldData}
          onClose={() => setShowProjectFieldSetupModal(false)}
          dataType={tableDataType.Project}
          projectId={projectId}
        />
      )}

      {showDeleteMetaFieldModal && (
        <DeleteMetaFieldModal
          uuid={editedFieldData.id}
          fieldName={editedFieldData.name}
          onClose={() => setShowDeleteMetaFieldModal(false)}
        />
      )}

      {showMemberOrClientModal && (
        <MemberOrClientModal
          onClose={() => setShowMemberOrClientModal(false)}
          openRolesInfoModal={() => setShowRolesInfoModal(true)}
          setIsNewOverviewForClient={setIsNewOverviewForClient}
          showAddProjectFieldModal={() => setShowAddProjectFieldModal(true)}
        />
      )}

      {settingsModalData.location === AppLocation.SingleProject && (
        <SettingsModal
          onClose={() => closeSettingsModal(history)}
          members={{
            openShareModal,
            openSeeAsClientInfoModal: () => setShowSeeAsClientInfoModal(true),
            openRolesInfoModal: () => setShowRolesInfoModal(true),
          }}
          contextTargetUuid={projectId}
        />
      )}

      {showSeeAsClientInfoModal && (
        <SeeAsClientInfoModal
          onClose={() => setShowSeeAsClientInfoModal(false)}
          openShareModal={openShareModal}
        />
      )}

      {showRulesInfoModal && (
        <RolesInfoModal
          onClose={() => setShowRolesInfoModal(false)}
          openShareModal={openShareModal}
        />
      )}

      {howItWorksModalData.location === AppLocation.SingleProject && (
        <HowItWorksModal
          showTransition
          onClose={() => {
            removePropertyFromViewParam(history, "howItWorks");
            openSettingsModal(
              history,
              AppLocation.SingleProject,
              SettingsContext.Project,
              SettingsTab.Extensions,
              ExtensionsSection.RecruitmentForm,
            );
          }}
        />
      )}

      {showTask && <Task onCloseHandler={() => dispatch(setShowTask(false))} />}
      {showGlobalTask && <GlobalTaskDetails />}
      {showDiscoveryDetails && <DictionaryDetails />}

      <WebsocketListener />
    </AnimatedDiv>
  );
}
