import React from "react";
import axios, { AxiosResponse } from "axios";

import { MetaFieldType } from "src/app/methods/getMetaFieldTypeOptions";
import {
  GlobalProjectMetaField,
  GlobalTaskMetaField,
} from "src/redux/settings/settingsReducer";
import { ExtensionTypeEnum, TaskType } from "src/types";
import { API_URLS } from "src/utils/API_URLS";
import { parseErrorMessages, showErrorToast } from "src/utils/methods";
import IDHFormattedMessage from "src/app/components/IDHFormattedMessage/IDHFormattedMessage";
import responseCodes from "src/utils/responseCodes";
import AutomationsEnum from "../Automations/AutomationsEnum";
import {
  MetaFieldFieldName,
  Template,
  TemplateProjectField,
  TemplateTaskField,
} from "./types";

export const mapFieldTypeToFieldName: Record<
  "project" | "task",
  "globalProjectMetaFieldsUuids" | "globalTaskMetaFieldsUuids"
> = {
  project: "globalProjectMetaFieldsUuids",
  task: "globalTaskMetaFieldsUuids",
};

export const ALLOWED_FIELD_TYPES = [
  MetaFieldType.Text,
  MetaFieldType.Member,
  MetaFieldType.Date,
  MetaFieldType.Number,
  MetaFieldType.Percent,
  MetaFieldType.Rating,
  MetaFieldType.Currency,
  MetaFieldType.MultiSelect,
  MetaFieldType.DictionaryElement,
];

export const filterFields = (
  field: GlobalProjectMetaField | GlobalTaskMetaField,
) => ALLOWED_FIELD_TYPES.includes(field.type) && field.valueSource === null;

interface HandleUpdateProjectTemplate extends Template {
  wsWorkspaceUuid: string;
}

interface HandleUpdateProjectTemplateParams {
  data: HandleUpdateProjectTemplate;
  catchCallback?: () => void;
}

export const handleUpdateProjectTemplate = async ({
  data,
  catchCallback,
}: HandleUpdateProjectTemplateParams) => {
  try {
    const response = await axios.put(API_URLS.updateProjectTemplate, data);
    if (response.status !== 200) {
      throw new Error("Could not update project template");
    }
  } catch (error) {
    if (catchCallback) {
      catchCallback();
    }
    if (axios.isAxiosError(error) && error.response?.data?.errors) {
      const errorMessage = parseErrorMessages(error.response.data.errors)[0]
        .message;
      showErrorToast({
        id: errorMessage,
        defaultMessage: errorMessage,
      });
    } else {
      showErrorToast({
        id: "ws_could_not_update_project_template",
        defaultMessage: "An error occurred while updating the template",
      });
    }
    console.error(error);
  }
};

export const projectTemplateExtensions = [
  {
    key: ExtensionTypeEnum.RecruitmentForm,
    name: (
      <IDHFormattedMessage
        id="ws_recruitment_form"
        defaultMessage="Recruitment form"
      />
    ),
    description: (
      <IDHFormattedMessage
        id="ws_recruitment_form_description"
        defaultMessage="Enable the recruitment of Creators through a dedicated form."
      />
    ),
  },
  {
    key: ExtensionTypeEnum.WsProjectLock,
    name: (
      <IDHFormattedMessage
        id="ws_lock_campaign"
        defaultMessage="Lock campaign editing"
      />
    ),
    description: (
      <IDHFormattedMessage
        id="ws_lock_campaign_description"
        defaultMessage="Extension for locking campaign editing functionality."
      />
    ),
  },
];

export const projectTemplateAutomations = [
  {
    key: AutomationsEnum.CreatorDetectionRunnableAutomation,
    name: (
      <IDHFormattedMessage
        id="ws_creators_detection"
        defaultMessage="Creators detection"
      />
    ),
  },
  {
    key: AutomationsEnum.PublicationDetectionRunnableAutomation,
    name: (
      <IDHFormattedMessage
        id="ws_publications_detection"
        defaultMessage="Publications detection"
      />
    ),
  },
  {
    key: AutomationsEnum.PublicationSynchronizationRunnableAutomation,
    name: (
      <IDHFormattedMessage
        id="ws_publications_synchronization"
        defaultMessage="Publications synchronization"
      />
    ),
  },
];

export const getUpdatedValuesArray = (
  checked: boolean,
  key: "automationsEnabled" | "extensionsEnabled",
  template: Template,
  name: AutomationsEnum | ExtensionTypeEnum,
) => {
  let updatedValues: Array<AutomationsEnum | ExtensionTypeEnum>;
  if (checked) {
    updatedValues = [...template[key], name];
  } else {
    updatedValues = (
      template[key] as Array<AutomationsEnum | ExtensionTypeEnum>
    ).filter((item) => item !== name);
  }
  return updatedValues;
};

export const templateWithNewFields = (
  template: Template,
  fieldName: MetaFieldFieldName,
  taskType: TaskType | "null",
  newFieldsData: TemplateProjectField[] | TemplateTaskField[],
) => {
  const updatedTemplate = { ...template };

  if (Array.isArray(updatedTemplate[fieldName])) {
    return { ...updatedTemplate, [fieldName]: { [taskType]: newFieldsData } };
  }

  const taskFields = updatedTemplate[fieldName][taskType] ?? [];

  return {
    ...updatedTemplate,
    [fieldName]: {
      ...updatedTemplate[fieldName],
      [taskType]: [...taskFields, ...newFieldsData],
    },
  };
};

export const templateWithRemovedFields = (
  template: Template,
  fieldName: MetaFieldFieldName,
  taskType: TaskType | "null",
  fieldsToRemove: string[],
) => {
  const updatedTemplate = { ...template };

  const fieldGroup = updatedTemplate[fieldName][taskType] ?? [];

  const updatedFieldGroup = fieldGroup.filter(
    (field) => !fieldsToRemove.includes(field.uuid),
  );

  return {
    ...updatedTemplate,
    [fieldName]: {
      ...updatedTemplate[fieldName],
      [taskType]: updatedFieldGroup,
    },
  };
};

export const templateWithUpdatedField = (
  template: Template,
  fieldName: MetaFieldFieldName,
  taskType: TaskType | "null",
  uuid: string,
  valueFieldName: string,
  updatedValue: string | boolean | null,
) => {
  const updatedTemplate = { ...template };
  const fieldGroup = updatedTemplate[fieldName][taskType] ?? [];

  const updatedFieldGroup = fieldGroup.map((item) => {
    if (item.uuid === uuid) {
      return { ...item, [valueFieldName]: updatedValue };
    }
    return item;
  });

  return {
    ...updatedTemplate,
    [fieldName]: {
      ...updatedTemplate[fieldName],
      [taskType]: updatedFieldGroup,
    },
  };
};

export const handleUpdateProjectTemplateRank = async (data: {
  uuid: string;
  rank: string;
}) => {
  try {
    const response = await axios.post(API_URLS.updateProjectTemplateRank, data);
    if (response.status !== responseCodes["200_OK"]) {
      throw new Error("Could not update project template rank");
    }
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.errors) {
      const errorMessage = parseErrorMessages(error.response.data.errors)[0]
        .message;
      showErrorToast({
        id: errorMessage,
        defaultMessage: errorMessage,
      });
    } else {
      showErrorToast({
        id: "ws_could_not_update_project_template",
        defaultMessage: "An error occurred while updating the template",
      });
    }
    console.error(error);
  }
};

export const rearrangeElementsInArray = (
  arr: TemplateProjectField[] | TemplateTaskField[],
  sourceIndex: number,
  targetIndex: number,
) => {
  const result = [...arr];

  const [element] = result.splice(sourceIndex, 1);

  result.splice(targetIndex, 0, element);

  return result;
};

export const getProjectTemplateDetails = async (uuid: string) => {
  try {
    const response: AxiosResponse<{ content: Template }> = await axios.get(
      API_URLS.getProjectTemplateDetails.replace(
        ":wsProjectTemplateUuid:",
        uuid,
      ),
    );
    if (response.status !== responseCodes["200_OK"]) {
      throw new Error("Could not get project template details");
    }
    return response.data.content;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.data?.errors) {
      const errorMessage = parseErrorMessages(error.response.data.errors)[0]
        .message;
      showErrorToast({
        id: errorMessage,
        defaultMessage: errorMessage,
      });
    } else {
      showErrorToast({
        id: "ws_could_not_get_project_template_details",
        defaultMessage: "An error occurred while getting the template details",
      });
    }
    console.error(error);
  }
};
